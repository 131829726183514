.Select-ControlValue {
	.TagBase {
		margin: 0 var(--tag-space) var(--tag-space) 0;
	}

	input {

		&:disabled,
		&:hover,
		&:focus {
			background-color: transparent;
		}
	}
}

.Select {
	.Select-SelectContainer:not(.Select-SelectContainer_multiple) {
		.Select-ControlValueContainer {
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}
}

.Sidebar {
	z-index: 10;
}


.Responses {
	height: 100%;
}

.Theme {
	&:not(.Popover, .Badge) {
		height: 100%;
	}

	#dnd-context>.Loader {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		width: 100%;
	}

	// кастомные переменные темы
	--transition-time: 150ms;
}

.TagBase {
	min-width: min-content;
}

.Popover {
	z-index: 10
}

.Checkbox-Input {
	background-color: transparent !important;

	&:checked {
		background-color: var(--checkbox-checked-background) !important;
	}
}


.TextField-Input {
	border-radius: unset;
	line-height: unset;

	&:disabled,
	&:hover,
	&:focus {
		background-color: transparent;
	}
}

.Select-Control {
	input {

		&:disabled,
		&:hover,
		&:focus {
			background-color: transparent;
		}

		&:focus,
		&:hover {
			color: var(--color-type-primary);
		}
	}
}

.Switch {
	input {

		&:focus,
		&:hover {
			background: unset;

			&:checked {
				background-color: var(--color-control-bg-primary) !important;
			}

		}
	}
}




// тут накинуто 4 высоты, по которым скейлится дропдаун селекта
// можно попробовать через js ставить переменную на ресайз и лоад
// @media (min-height: --var) {
// 	max-height: calc(--var / 2 + 200px) !important;
// }

.SelectDropdown-ScrollContainer,
.Popover>* {
	@media (min-height: 800px) {
		max-height: 300px !important;
	}

	@media (min-height: 1000px) {
		max-height: 400px !important;
	}

	@media (min-height: 1200px) {
		max-height: 500px !important;
	}

	@media (min-height: 1400px) {
		max-height: 600px !important;
	}
}